<template>
  <div v-if="currentUser && nickname">

    <div>
      <div> 
        <div class="page-header">{{nickname}}</div>
      </div>

      <div class="page-content-with-command" >
        <form-user @event-user-profile-saved="opened"></form-user>
      </div>

      <command-user></command-user>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import * as constants from '../config/constants';
import UserForm from "./UserForm.vue";
import CommandUser from "@/components/CommandUsers.vue";

export default {
    computed: mapGetters(['currentUser']), 
    data() {
        return {
          nickname: ''
        };
    },
    methods: {
        opened() {
          if(this.currentUser) {
            this.nickname = this.currentUser.nickname;
          }
        },
        setFilter(filter) {
          this.filterActive = filter;
        }
    },
    created() {
      this.opened();
    },
    components: {
      'command-user': CommandUser,
      'form-user': UserForm
    }
};
</script>

<style scoped>
.page-header {
    width: 1416px;
}
</style>