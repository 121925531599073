<template>
    <div>
        <div class="nub-card">
            <div class="user-card">
                <div class="nub-card-header-big">{{$t('profileForm.mainLabel')}}</div>
                <div>
                    <form-input-component :title="$t('profileForm.username')" v-bind:value.sync="user.nickname" :cw="inputWidth" isRequired="true"></form-input-component>
                    <form-input-component :title="$t('profileForm.login')+' (e-mail)'" v-bind:value.sync="user.username" 
                        :cw="inputWidth" isDisabled="true"></form-input-component>
                    <form-input-component :title="$t('profileForm.company')" v-bind:value.sync="user.company_id" :company="{id: user.company_id, title: user.company_title, logo: user.logo}"
                        :cw="inputWidth" t="company" isDisabled="true" isHidden="true"></form-input-component>
                    <div class="button-change-pass button-as-text" v-bind:style="{width: inputWidth}">
                        <div class="button-change-pass-inner" @click="isShowDialog=true">{{$t('buttons.chPass')}}</div>
                    </div>
                    <div class="w100 object-left">
                        <form-input-component :title="$t('profileForm.title')" v-bind:value.sync="user.post" :cw="inputWidth"></form-input-component>
                    </div>
                    <div class="w100 object-left"><form-input-component :title="$t('profileForm.phone')" v-bind:value.sync="user.phone" :cw="inputWidth" t="phone"></form-input-component></div>
                </div>
            </div>
        </div>
        <button class="button-primary button-save" @click="saveUser" :disabled="isLoading || !isSaveEnabled">{{$t('buttons.save')}}</button>
        <save-changes-prompt></save-changes-prompt>

        <el-dialog
            :visible.sync="isShowDialog"
            width="527px"
            destroy-on-close
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false">
            <form class="d-body" autocomplete="off">
                <div class="chpwd-dialog-title">{{$t('passChange.passCh')}}</div>
                <form-input-component :title="$t('passChange.passCurrent')" v-bind:value.sync="pass" :cw="'415px'" :isWrong="false" t='password'></form-input-component>
                <form-input-component :title="$t('passChange.passNew1')" v-bind:value.sync="newPass" :cw="'415px'" :isWrong="isWrongNewPass" t='password'></form-input-component>
                <form-input-component :title="$t('passChange.passNew2')" v-bind:value.sync="newPassRepeat" :cw="'415px'" :isWrong="isWrongNewPassRepeat" t='password'></form-input-component>
            </form>
            <div slot="footer" class="d-buttons">
                <el-button round class="del-dialog-cancel" @click="isShowDialog=false">{{$t('buttons.cancel')}}</el-button>
                <el-button type="primary" round @click="changePassword">{{$t('buttons.applay')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import * as constants from '../config/constants';
import FormInputComponent from "@/components/FormInputComponent.vue";
import SaveChangesPrompt from "@/components/SaveChangesPrompt.vue";
import server from '@/api/server-model';
import serverAccount from '../../api/server-account';
import utilites from '../../lib/utilites';

export default {
    computed: {
        isSaveEnabled() {
            if(!this.user.nickname || !this.user.username) {
                return false;
            }
            return true;
        },
        ... mapGetters(['currentUser'])
    },
    data() {
        return {
            user: {},
            isLoading: false,
            inputWidth: '450px',
            //для смены пароля
            isShowDialog: false,
            pass: null,
            newPass: null,
            newPassRepeat: null,
            isWrongNewPass: false,
            isWrongNewPassRepeat: false
        };
    },
    methods: {
        opened() {
            //INFO: если this.$set(this, 'user', this.currentUser); -- то меняются значения прямо 
            //      в currentUser, и даже не нажимаю кнопку save кажется, что изменеия сохранились, что
            //      коряво. если Object.assign(user, this.currentUser), то user становится не реактивным!
            let temp = {};
            Object.assign(temp, this.currentUser);
            this.$set(this, 'user', temp);
        },
        saveUser() {
            this.isLoading = true;
            server.saveModel("account", this.user, (isSuccess) => {
                this.isLoading = false;
                this.$store.commit('setGuiIsComponentChanged');
                if(isSuccess) {
                    this.$store.dispatch('retriveCurrentUserInfo').then(() => {
                        this.$store.commit('addMessageInfo', this.$t('recordSaved'));
                        this.opened();
                        this.$emit("event-user-profile-saved");
                    });
                } else {
                    this.$store.commit('addMessageError', this.$t('recordSavingError'));
                }
            }, 'isSuccess');
        },
        changePassword() {
            this.isWrongNewPass = false;
            this.isWrongNewPassRepeat = false;
            if(this.newPass != this.newPassRepeat) {
                this.$store.commit('addMessageError', this.$t('passChange.changeMismatch'));
                this.isWrongNewPassRepeat = true;
            } else {
                if(!utilites.checkPassword(this.newPass)) {
                    this.isWrongNewPass = true;
                } else {
                    serverAccount.updatePassword({current: this.pass, newPass: this.newPass}, isSuccess => {
                        this.$store.commit('setGuiIsComponentChanged');
                        if(isSuccess) {
                            // this.pass = null;
                            // this.newPass = null;
                            // this.newPassRepeat = null;
                            this.$store.commit('addMessageInfo', this.$t('passChange.changed'));
                            this.isShowDialog = false;
                        }
                    });
                }
            }
        }
    },
    created() {
        // this.userForm.nickname = 'wbha'
      this.opened();
    },
    components: {
        'form-input-component': FormInputComponent,
        'save-changes-prompt': SaveChangesPrompt
    }
};
</script>

<style scoped>
.user-card {
    height: 600px;
}

.button-save {
    float: right;
    width: 149px;
    height: 50px;
    margin-top: 20px;
}

.button-change-pass {
    height: 60px;
    float: left;
    position: relative;
    margin: 24px 24px;
}
.button-change-pass-inner {
    position: absolute;
    bottom: 0;
    margin-left: 16px;
}
.chpwd-dialog-title {
  height: 29px;
  font-size: 30px;
  font-weight: 300;
  text-align: left;
  margin: 0 0 46px 34px;
  color: #25272f;
}
.d-buttons {
    margin-top: 320px;
    margin-right: 20px;
}
</style>